<template>
  <a-layout :style="{ minHeight: '100vh' }">
    <a-layout-header
      class="no-inline-padding-header"
      :style="{ position: 'fixed', zIndex: 1, width: '100%' }"
      :collapsed="siderCollapsed"
      :trigger="null"
      collapsible
    >
      <Sider />
    </a-layout-header>

    <a-layout-content class="overflow-hidden pt-[64px] pb-[128px]">
      <Error v-if="state.isError" />
      <router-view v-else v-slot="{ Component, route }">
        <transition
          enter-active-class="transition-all duration-1000"
          enter-from-class="opacity-0 -translate-x-16"
        >
          <keep-alive :include="keepAliveIncludeItems">
            <component :is="Component" :key="route.fullPath" />
          </keep-alive>
        </transition>
      </router-view>
    </a-layout-content>

    <a-layout-footer style="position: fixed; bottom: 0; width: 100%" class="border-t-[1px]">
      <div class="flex justify-start gap-10">
        <div class="mt-2">
          <img :src="logo" alt="logo" class="h-14 top-[4px] relative float-left" /><span
            class="float-left mt-4 font-extrabold text-xl"
            >{{ t('garbage_can') }}</span
          >
        </div>

        <div>
          <div
            class="mb-2 cursor-pointer font-bold"
            @click="$router.push('/operating-company-information')"
          >
            ➤ {{ t('management_company_information') }}
          </div>

          <div class="mb-2 cursor-pointer font-bold" @click="$router.push('/personal-information')">
            ➤ {{ t('about_personal_information') }}
          </div>
          <div class="cursor-pointer font-bold" @click="$router.push('/site-policy')">
            ➤ {{ t('site_policy') }}
          </div>
        </div>
      </div>
      <div class="text-center text-sm mt-2">Copyright @ 2024 ゴミカン All Rights Reserved.</div>
    </a-layout-footer>
  </a-layout>
</template>

<script setup lang="ts">
import { computed, onMounted, onErrorCaptured, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLayoutStore } from '@/stores';
import Header from './Header.vue';
import Sider from './Sider.vue';
import Tabbar from './Tabbar.vue';
import Error from './Error.vue';
import logo from '@/assets/images/logo.png';
import { CaretRightOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const state = reactive({
  isError: false
});
const router = useRouter();
const layoutStore = useLayoutStore();
const keepAliveIncludeItems = computed(() => layoutStore.tabbarItems.map((val) => val.path));
const { siderCollapsed } = storeToRefs(layoutStore);

onMounted(() => {
  window.addEventListener('resize', () => {
    const screenWidth = document.body.clientWidth;
    if (layoutStore.siderCollapsed === false && screenWidth < 1300)
      layoutStore.$patch({ siderCollapsed: true });
  });
});

router.beforeResolve(() => {
  state.isError = false;
});

onErrorCaptured((err) => {
  state.isError = true;
  console.error('--> onErrorCaptured.err', err);
  return false;
});
</script>

<style scoped>
.ant-layout .ant-layout-header {
  height: 64px;
  padding-inline: 0.5rem;
  color: rgba(0, 0, 0, 0.88);
  line-height: 64px;
  background: #001529;
}
</style>

<style lang="scss">
@font-face {
  font-family: msgothic;
  src: url('@/assets/fonts/msgothic.ttc') format('truetype');
  font-weight: normal;
}
body {
  font-family: 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Arial',
    'Yu Gothic', 'Meiryo', sans-serif;
}

.no-inline-padding-header {
  padding-inline: 0 !important;
}

.ant-layout-footer {
  background: white !important;
}
</style>
