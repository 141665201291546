<template>
  <div class="min-h-screen bg-[#f0f2f5]">
    <a-config-provider
      :locale="locale === 'en' ? enUS : jaJP"
      :form="{
        validateMessages: {
          required: $t('MSG_00_21')
        }
      }"
    >
      <a-style-provider hash-priority="high">
        <router-view></router-view>
      </a-style-provider>
    </a-config-provider>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import enUS from 'ant-design-vue/es/locale/en_US';
import jaJP from 'ant-design-vue/es/locale/ja_JP';
const { t } = useI18n();
import { ref } from 'vue';

const locale = ref('ja');
</script>
<style>
html {
  scroll-behavior: smooth;
}
</style>
