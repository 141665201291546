import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import { createPinia } from 'pinia';
import type { RouteRecordRaw } from 'vue-router';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import './styles/tailwind.css';
import 'ant-design-vue/dist/reset.css';
import './styles/antd.css';
import { getAuthInfo } from './services';
import { selfRouters, router } from './router';
import App from './App.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far);

import { createI18n } from 'vue-i18n';

import ja from '@/lang/ja.json';
import en from '@/lang/en.json';

const messages = {
  ja,
  en
};

let locale = localStorage.getItem('language') || 'ja';

const i18n = createI18n({
  locale: locale,
  fallbackLocale: 'ja',
  silentFallbackWarn: true,
  allowComposition: true,
  legacy: false,
  messages
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
const app = createApp(App);
app.use(Antd);

app.use(pinia);
app.use(router);

app.use(i18n);
app.component('font-awesome-icon', FontAwesomeIcon);

let isInitRouter = false;
router.beforeEach(async (to) => {
  if (!isInitRouter) {
    const authInfo = await getAuthInfo();

    const getAuthItem = (routeItem: RouteRecordRaw) => {
      routeItem.children = routeItem.children?.filter((item: RouteRecordRaw) => {
        if (!item.meta?.auth) return true;
        if (item.meta?.auth && authInfo.includes(item.meta.auth)) return true;
        if (item.children && item.children.length > 0) item = getAuthItem(item);
        return false;
      });
      return routeItem;
    };

    selfRouters.forEach((cur) => {
      const authItem = getAuthItem(cur);
      router.addRoute(authItem);
    });
    isInitRouter = true;
    // 等待动态路由加载完成再挂载
    app.mount('#app');
    // 触发重定向
    return to.fullPath;
  }
});
