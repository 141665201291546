import http from '../http';

/**
 * @namespace 角色权限模块
 */

/**
 * 获取权限列表
 * @returns {Promise<any>}
 */
export const sendInquiry = (
  companyName: string,
  contactName: string,
  phoneNumber: string,
  email: string,
  purpose: string,
  content: string
) => {
  return http.post('/contact-us', {
    companyName,
    contactName,
    phoneNumber,
    email,
    purpose,
    content
  });
};
