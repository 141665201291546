import { createRouter, createWebHistory } from 'vue-router';
import { HomeOutlined } from '@ant-design/icons-vue';
import Layout from '@/layout/Layout.vue';
import NotFound from '@/views/404.vue';
import Forbidden from '@/views/403.vue';
import Home from '@/views/Home.vue';
/**
 * @description 路由配置项 meta 参数
 * @param {Boolean} isHide 是否在左侧菜单栏隐藏
 * @param {String} icon 左侧菜单栏首级图标名称（在 Sider.vue 引入同名组件）
 * @param {String} title 左侧菜单栏和 tabbar 名称
 * @param {Boolean} noTabbar 不添加到 tabbar
 * @param {Boolean} root 是否模块根
 */
declare module 'vue-router' {
  interface RouteMeta {
    isHide?: boolean;
    icon?: any;
    title?: string;
    noTabbar?: boolean;
    root?: boolean;
    auth?: string;
  }
}
export const selfRouters = [];
const routes = [
  {
    path: '/',
    redirect: '/home',
    component: Layout,
    meta: { title: 'garbage_can', root: true },
    children: [
      {
        name: 'home',
        path: '/home',
        component: Home,
        meta: { title: '首页', isHide: true }
      }
    ]
  },
  {
    path: '/home#challenges',
    name: 'challenges',
    component: Layout,
    meta: { title: 'waste_management_challenges', root: true }
  },
  {
    path: '/home#cases',
    name: 'cases',
    component: Layout,
    meta: { title: 'what_a_garbage_can_can_do', root: true }
  },
  {
    path: '/home#solutions',
    name: 'solutions',
    component: Layout,
    meta: { title: 'garbage_can_functions', root: true }
  },
  {
    path: '/home#contact-us',
    name: 'contact',
    component: Layout,
    meta: { title: 'contact_us', root: true }
  },
  {
    path: '/operating-company-information',
    name: 'operating-company-information',
    component: Layout,
    meta: { title: 'management_company_information' },
    children: [
      {
        path: '/operating-company-information',
        name: 'operating-company-information',
        component: () => import('@/views/OperatingCompanyInformation.vue'),
        meta: { title: 'management_company_information', isHide: true }
      }
    ]
  },
  {
    path: '/personal-information',
    name: 'personal-information',
    component: Layout,
    meta: { title: 'about_personal_information' },
    children: [
      {
        path: '/personal-information',
        name: 'personal-information',
        component: () => import('@/views/PersonalInformation.vue'),
        meta: { title: 'about_personal_information', isHide: true }
      }
    ]
  },
  {
    path: '/site-policy',
    name: 'site-policy',
    component: Layout,
    meta: { title: 'site_policy' },
    children: [
      {
        path: '/site-policy',
        name: 'site-policy',
        component: () => import('@/views/SitePolicy.vue'),
        meta: { title: 'site_policy', isHide: true }
      }
    ]
  },
  {
    path: '/403',
    name: '403',
    component: Forbidden,
    meta: { isHide: true, root: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound,
    meta: { isHide: true, root: true }
  }
];
export const router = createRouter({
  history: createWebHistory(),
  routes: routes
});
