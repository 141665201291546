<template>
  <div>
    <a-row :gutter="12">
      <a-col :md="6" :xs="24">
        <img src="@/assets/images/contact.png" alt="contact" class="w-full object-cover" />
      </a-col>

      <a-col :md="18" :xs="24" class="mt-2 md:mt-0">
        <div class="mb-2 font-bold text-[#0361B1] flex items-center">
          <img src="@/assets/images/contact-us.png" alt="contact" class="w-20 object-cover mr-2" />
          <span>
            {{ t('for_inquiries_regarding_garbage_cans_please_contact_us_here') }}
          </span>
        </div>
        <a-divider />
        <a-form
          class="required-indented !ml-0 md:!ml-[90px]"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :labelAlign="'left'"
          hideRequiredMark
          :rules="rules"
          ref="contactForm"
        >
          <a-form-item :label="t('company_name')" name="companyName">
            <RequiredArea />
            <a-input v-model:value="formState.companyName" class="!w-full md:!w-1/2" />
          </a-form-item>
          <a-form-item :label="t('person_in_charge_name')" name="contactName">
            <a-input
              v-model:value="formState.contactName"
              class="!w-full md:!w-1/2 md:!ml-[40px]"
            />
          </a-form-item>
          <a-form-item :label="t('telephone_number')" name="phone">
            <RequiredArea />
            <a-input
              v-model:value="formState.phone"
              class="!w-full md:!w-1/2"
              maxlength="13"
              @input="formatTelephone($event)"
            />
          </a-form-item>
          <a-form-item :label="t('email_address')" name="email">
            <RequiredArea />
            <a-input v-model:value="formState.email" class="!w-full md:!w-1/2" />
          </a-form-item>
          <a-form-item :label="t('purpose_of_inquiry')" name="purpose">
            <RequiredArea />
            <a-select
              ref="select"
              v-model:value="formState.purpose"
              :placeholder="t('please_select')"
              class="!w-full md:!w-1/2"
            >
              <a-select-option value="資料請求がしたい">{{
                t('i_want_to_request_information')
              }}</a-select-option>
              <a-select-option value="デモをお願いしたい">{{
                t('i_would_like_to_request_a_demo')
              }}</a-select-option>
              <a-select-option value="ゴミカンの利用がしたい">{{
                t('i_want_to_use_the_garbage_can')
              }}</a-select-option>
              <a-select-option value="ゴミカンに関して詳しく話が聞きたい">{{
                t('i_would_like_to_hear_more_about_garbage_cans')
              }}</a-select-option>
              <a-select-option value="その他">{{ t('others') }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="t('inquiry_details')" name="desc">
            <a-textarea
              class="md:!ml-[40px] w-full md:!w-[calc(100%-40px)]"
              v-model:value="formState.desc"
              :placeholder="
                t('if_you_have_any_supplementary_details_for_your_inquiry_please_provide_details')
              "
            />
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
    <div class="flex justify-center">
      <a-button class="!min-w-[200px] bg-blue" type="primary" @click="handleSend">{{
        t('send')
      }}</a-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, toRaw, watch } from 'vue';
import type { UnwrapRef } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import RequiredArea from '@/components/RequiredArea.vue';
import { sendInquiry } from '@/services';
import { message } from 'ant-design-vue';

interface FormState {
  companyName: string;
  contactName: boolean;
  phone: string;
  email: string;
  purpose: string;
  desc: string;
}
const formState = ref({
  companyName: '',
  contactName: '',
  phone: '',
  email: '',
  purpose: null as any,
  desc: ''
});

defineExpose({ formState });

const labelCol = { span: 24, md: 6, xl: 4 };
const wrapperCol = { span: 24, md: 18, xl: 20 };
const rules = {
  companyName: [{ required: true }],
  phone: [
    {
      required: true,
      pattern: /^(\d{2}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4})$/,
      message: '電話番号フォーマットが合っていません。'
    }
  ],
  email: [
    { required: true, type: 'email', message: 'メールアドレスのフォーマットに合っていません' }
  ],
  purpose: [{ required: true }]
};

const formatTelephone = (event: Event) => {
  const input = event.target as HTMLInputElement;
  let telephone = input.value.replace(/\D/g, '');

  if (telephone.length > 10) {
    telephone = telephone.slice(0, 3) + '-' + telephone.slice(3, 7) + '-' + telephone.slice(7, 11);
  } else if (telephone.length > 6) {
    telephone = telephone.slice(0, 2) + '-' + telephone.slice(2, 6) + '-' + telephone.slice(6);
  } else if (telephone.length > 2) {
    telephone = telephone.slice(0, 2) + '-' + telephone.slice(2);
  }

  formState.value.phone = telephone;
  input.value = telephone;
  console.log('formstate.value', formState.value.phone);
};

const contactForm = ref();
const handleSend = async () => {
  contactForm.value.validate().then((valid: any) => {
    if (valid) {
      onSubmit();
    }
  });
};

const onSubmit = async () => {
  try {
    await sendInquiry(
      formState.value.companyName,
      formState.value.contactName,
      formState.value.phone,
      formState.value.email,
      formState.value.purpose,
      formState.value.desc
    );
    contactForm.value.resetFields();
    message.success(t('your_inquiry_has_been_successfully_submitted'));
  } catch (error) {
    message.error(t('sending_your_inquiry_failed'));
    console.error(error);
  }
};
</script>
