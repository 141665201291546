<template>
  <span class="required-area">
    <span class="required-label">
      {{ $t('required') }}
    </span>
  </span>
  <slot></slot>
</template>

<script>
export default {
  name: 'RequiredArea'
};
</script>

<style scoped lang="scss">
.required-area {
  width: 32px;
  flex: none;
  margin-right: 8px;
  display: inline-flex;
  vertical-align: top;
  margin-top: 4.5px;

  .required-label {
    background-color: red;
    color: white;
    padding: 3px 5px;
    border-radius: 3px;
    font-size: 11px;
  }
}

:slotted(input),
:slotted(textarea),
:slotted(div) {
  width: calc(100% - 40px) !important;
}
</style>
