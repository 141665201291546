<template>
  <a-menu
    v-if="state"
    :open-keys="openKeys"
    :selected-keys="state.exactSelectedKeys"
    mode="horizontal"
    class="!px-[0px] md:!px-[34px]"
    @click="onClickMenuItem"
  >
    <template v-for="menuItem in state.menus" :key="menuItem.path">
      <a-menu-item v-if="!menuItem.children" :key="menuItem.path">
        <template #icon>
          <component v-if="menuItem.path != '/'" :is="menuItem.icon" />
          <img v-else :src="logo" alt="logo" class="h-14 top-[4px] relative" />
        </template>
        <span
          :class="menuItem.path == '/' ? 'text-xl font-black relative bottom-4' : ''"
          class="text-base"
          >{{ $t(menuItem.title) }}</span
        >
      </a-menu-item>
      <a-sub-menu v-else :key="menuItem.path" @click="onClickSubMenu(menuItem)">
        <template #icon>
          <component :is="menuItem.icon" />
        </template>
        <template #title>{{ menuItem.title }}</template>
        <template v-for="subMenuItem in menuItem.children" :key="subMenuItem.path">
          <a-menu-item v-if="!subMenuItem.children" :key="subMenuItem.path">
            <span>{{ $t(subMenuItem.title) }}</span>
          </a-menu-item>
          <a-sub-menu
            v-else
            :key="subMenuItem.path"
            @click.stop="onClickSecondSubMenu(subMenuItem)"
          >
            <template #title>{{ subMenuItem.title }}</template>
            <a-menu-item v-for="child in subMenuItem.children" :key="child.path">
              {{ $t(child.title) }}
            </a-menu-item>
          </a-sub-menu>
        </template>
      </a-sub-menu>
    </template>
    <a-menu-item :key="'download'">
      <img
        src="@/assets/images/download.jpg"
        alt="logo"
        class="h-12 mt-2"
        @click="downloadMaterials"
      />
    </a-menu-item>
  </a-menu>
</template>

<script setup lang="ts">
import { computed, reactive, onMounted, watch, ref } from 'vue';
// import { HomeOutlined, UserOutlined, BarsOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import type { RouteMeta, RouteRecordRaw } from 'vue-router';
import { useLayoutStore } from '@/stores';
import logo from '@/assets/images/logo.png';
import { CloudDownloadOutlined } from '@ant-design/icons-vue';
import { downloadMaterials } from '@/utils';

type MenuItem = RouteMeta & { path: string } & { children?: MenuItem[] };
interface State {
  menus: MenuItem[];
  exactOpenKeys: string[];
  exactSelectedKeys: string[];
}

const state = ref<State>({
  menus: [],
  exactOpenKeys: [],
  exactSelectedKeys: ['/']
});
const router = useRouter();
const currentRoute = useRoute();
const layoutStore = useLayoutStore();
state.value.exactSelectedKeys = currentRoute.matched.map((val) => val.path);
state.value.exactOpenKeys = state.value.exactSelectedKeys.slice(0, -1);
const openKeys = computed(() => (layoutStore.siderCollapsed ? [] : state.value.exactOpenKeys));

// 根据路由转换菜单（二三级）
const updateMenuOnRouter = () => {
  const routers = router.getRoutes().filter((val) => val.meta.root);
  state.value.menus = routers.reduce((total: MenuItem[], cur: RouteRecordRaw) => {
    const parent: MenuItem = { path: cur.path, ...cur.meta };
    if (parent.isHide) return total;
    if (cur.children) {
      const items = cur.children.filter((val) => val.meta && !val.meta.isHide);
      const childs = items.map((item: RouteRecordRaw) => {
        const current: MenuItem = { path: item.path, ...item.meta };
        if (item.children)
          current.children = item.children.map((val) => ({ path: val.path, ...val.meta }));
        return current;
      });
      if (childs.length > 0) {
        parent.children = childs;
      }
    }
    return [...total, parent];
  }, []);
};

const onClickMenuItem = async ({ key, keyPath }: { key: string; keyPath: string[] }) => {
  if (key === 'download') {
    return;
  }

  state.value.exactSelectedKeys = keyPath;
  let currentRoute = router.currentRoute.value;
  if (key.includes('#')) {
    if (key.includes(currentRoute.path)) {
      setTimeout(() => {
        let el = document.getElementById(key.split('#')[1]);
        el.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }, 0);
    } else {
      await router.push(key);
      document
        .getElementById(key.split('#')[1])
        ?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      state.value.exactSelectedKeys = keyPath;
    }
  } else {
    if (key.includes('/home') || key === '/') {
      await router.push(key);
      setTimeout(() => {
        let top = document.getElementById('top-page');
        top.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }, 0);
    } else {
      router.push(key);
    }
  }
};

const onClickSubMenu = ({ path }: MenuItem) => {
  state.value.exactOpenKeys = [path];
};

const onClickSecondSubMenu = ({ path }: MenuItem) => {
  state.value.exactOpenKeys.push(path);
};

watch(
  () => currentRoute.fullPath,
  () => {
    state.value.exactSelectedKeys = currentRoute.matched.map((val) => val.path);
    state.value.exactOpenKeys = state.value.exactSelectedKeys.slice(0, -1);
  }
);

onMounted(() => {
  updateMenuOnRouter();
});
</script>

<style lang="scss">
.ant-menu-overflow {
  justify-content: space-between;
}

.ant-menu::before {
  display: none !important;
}

.ant-menu::after {
  display: none !important;
}

.ant-menu {
  height: 64px;
}

html {
  scroll-behavior: smooth;
}
</style>
