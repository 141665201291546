<template>
  <div class="card-container">
    <div id="top-page" class="bg-[#DDEBF7] content-1 pt-6">
      <div
        class="flex flex-col md:flex-row justify-between items-center mb-8 mx-4 md:mx-14 py-2 pb-7"
      >
        <div class="w-full md:w-1/2">
          <h1 class="text-2xl font-bold mb-4">{{ t('cloudbased_waste_management_system') }}</h1>
          <br />
          <br />
          <br />
          <h2 class="text-4xl font-bold mb-4">
            {{ t('all_operations_related_to_business_waste') }}
          </h2>
          <h2 class="text-4xl font-bold mb-4">{{ t('all_online_and_efficiently') }}</h2>
          <br />
          <br />
          <br />
          <p class="text-lg">
            {{ t('electronic_data_management_helps_achieve_carbon_neutrality_in_the_future') }}
          </p>
        </div>

        <div class="w-full md:w-2/3 lg:w-1/2 xl:w-2/5">
          <img
            src="@/assets/images/gomikan.jpg"
            alt="Waste management system interface"
            class="w-full rounded-lg shadow-lg"
            @load="onImageLoad"
          />
        </div>
      </div>
    </div>
    <div class="download-button-container mt-56">
      <a-button
        id="downloadButton"
        class="bg-blue"
        type="primary"
        size="large"
        @click="downloadMaterials"
      >
        <CloudDownloadOutlined #icon />
        <span class="ml-2">{{ t('first_download_the_document') }}</span>
      </a-button>
    </div>
    <!-- Placeholder for more content to be added below -->
    <div id="challenges" class="more-content mt-6 pt-6 mx-[16px] md:mx-[72px]">
      <h1 class="text-center text-3xl font-bold mb-4 whitespace-pre-line">
        {{
          t(
            'do_you_have_any_of_these_concerns_when_dealing_with_wasterelated_businesses_and_governments'
          ).replace(/\\n/g, '\n')
        }}
      </h1>

      <div class="flex flex-col md:flex-row justify-between mb-12 mt-12">
        <div v-for="(c, index) in caseList" :key="index" class="w-full md:w-1/3 px-4 mb-4 md:mb-0">
          <div class="!text-blue-400 !text-lg flex items-start justify-center">
            <img src="@/assets/images/case.jpg" alt="Case" class="w-24 mr-4" />
            <div>
              <div class="text-[#0361B1] text-xl font-semibold">Case {{ index + 1 }}</div>
              <div class="text-[#0361B4] font-thin">{{ c.title }}</div>
              <div class="text-[#0361B4] font-thin">{{ c.description }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center">
        <img src="@/assets/images/arrows.jpg" alt="Arrows" class="w-20" />
      </div>

      <a-row
        type="flex"
        justify="center"
        class="flex flex-col md:flex-row items-center ml-0 xl:ml-60"
      >
        <div class="flex items-center mr-5 mb-4 md:mb-0 text-center">
          <img src="@/assets/images/bulb.jpg" alt="Bulb" class="w-24 mr-5" />
          <div>
            <div class="text-3xl font-bold">{{ t('garbage_cans_are_for_waste_management') }}</div>
            <div class="text-3xl font-bold">{{ t('we_can_solve_various_problems') }}</div>
          </div>
        </div>
        <img
          src="@/assets/images/gomikan.jpg"
          alt="Waste management interface"
          class="mt-3 w-full md:w-2/3 lg:w-1/3 xl:w-1/4 rounded-lg shadow-lg"
        />
      </a-row>
    </div>

    <div id="cases" class="more-content mt-18 pt-6 mx-[16px] md:mx-[72px]">
      <div
        v-for="(c, index) in caseList"
        :key="index"
        class="mb-5 flex flex-col md:flex-row justify-center items-center max-w-screen-2xl mx-auto gap-14"
      >
        <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 pr-4 mb-4 md:mb-0 flex justify-center">
          <img
            v-if="index === 0"
            src="@/assets/images/case-1.png"
            :alt="'Case ' + (index + 1)"
            class="w-full"
          />
          <img
            v-else-if="index === 1"
            src="@/assets/images/case-2.png"
            :alt="'Case ' + (index + 1)"
            class="w-full"
          />
          <img
            v-else
            src="@/assets/images/case-3.png"
            :alt="'Case ' + (index + 1)"
            class="w-full"
          />
        </div>
        <div class="w-full md:w-1/2 lg:w-2/3 xl:w-3/4 flex flex-col items-center md:items-start">
          <div class="flex items-center mb-6 text-xl">
            <span class="text-xl font-bold text-[#0361B1] mr-6">Case {{ index + 1 }}</span>
            <span class="text-[#0361B1]">{{ c.problem }}</span>
          </div>
          <div class="ml-0 md:ml-48 lg:ml-64 xl:ml-72 mb-8 text-center md:text-left">
            <img src="@/assets/images/arrows.jpg" alt="Arrows" class="w-12" />
          </div>
          <div class="flex items-center mb-2 text-xl">
            <h2 class="text-xl font-bold text-blue-600 mr-6 invisible">Case {{ index + 1 }}</h2>
            <p class="font-semibold text-center md:text-left">{{ c.solution }}</p>
          </div>
        </div>
      </div>

      <div class="bg-[#0361B1] text-white p-6 rounded-2xl mt-12 max-w-screen-2xl mx-auto">
        <p class="text-center text-xl mb-6">
          {{ t('application_is_completed_in_30_seconds_') }}
        </p>
        <div class="flex justify-between items-center">
          <div class="w-5/12 text-center">
            <p class="text-lg mb-4">
              {{ t('for_those_who_want_to_know_more_about_features_and_costs') }}
            </p>
            <a-button
              class="w-52 !text-blue-600"
              shape="round"
              size="large"
              @click="downloadMaterials"
              >{{ t('product_document_download') }}
            </a-button>
          </div>
          <div class="w-1/12 flex justify-center">
            <div class="h-16 w-px bg-white"></div>
          </div>
          <div class="w-5/12 text-center">
            <p class="text-lg mb-4">
              {{ t('for_those_who_want_to_actually_see_the_system_screen') }}
            </p>
            <a-button
              type="primary"
              class="!bg-[#FFD866] !border-[#FFD866] !text-blue-600 w-52"
              shape="round"
              size="large"
              @click="openDemo"
            >
              {{ t('apply_for_free_demo') }}
            </a-button>
          </div>
        </div>
      </div>
    </div>

    <div
      id="solutions"
      class="more-content mt-42 pt-6 md:px-10 lg:px-14 xl:px-20 mx-[16px] md:mx-[72px]"
    >
      <h1 class="text-3xl font-bold text-center mb-24">
        {{ t('a_garbage_can_can_handle_all_kinds_of_tasks') }}
      </h1>

      <div class="ml-7 pl-9">
        <a-timeline>
          <a-timeline-item v-for="(task, index) in tasks" :key="index">
            <template #dot>
              <img v-if="index === 0" src="@/assets/images/1.jpg" alt="Task" class="w-32 mr-8" />
              <img
                v-else-if="index === 1"
                src="@/assets/images/2.jpg"
                alt="Task"
                class="w-32 mr-8"
              />
              <img
                v-else-if="index === 2"
                src="@/assets/images/3.jpg"
                alt="Task"
                class="w-32 mr-8"
              />
              <img
                v-else-if="index === 3"
                src="@/assets/images/4.jpg"
                alt="Task"
                class="w-32 mr-8"
              />
              <img
                v-else-if="index === 4"
                src="@/assets/images/5.jpg"
                alt="Task"
                class="w-32 mr-8"
              />
              <img
                v-else-if="index === 5"
                src="@/assets/images/6.jpg"
                alt="Task"
                class="w-32 mr-8"
              />
              <img v-else src="@/assets/images/7.jpg" alt="Task" class="w-32 mr-8" />
            </template>
            <div class="ml-12">
              <h2 class="text-xl font-bold text-[#0361B1] mb-4">{{ task.description }}</h2>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div
                  v-for="(screen, screenIndex) in task.screens"
                  :key="screenIndex"
                  class="w-full flex flex-col md:flex-row mb-4"
                >
                  <div class="flex flex-col md:w-1/2 text-center">
                    <div>
                      <img
                        :src="getImageUrl(index, screenIndex)"
                        :alt="screen.title"
                        class="w-full rounded shadow-md mb-2"
                      />
                      <div class="text-lg mb-4">{{ screen.description }}</div>
                    </div>
                  </div>
                  <div class="flex-1 md:ml-4">
                    <div v-for="(detail, detailIndex) in screen.details" :key="detailIndex">
                      <div class="whitespace-pre-line text-lg font-thin">
                        {{ detail.description.replace(/\\n/g, '\n') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-timeline-item>
        </a-timeline>
      </div>

      <div class="bg-[#0361B1] text-white p-6 rounded-2xl mt-12 max-w-screen-2xl mx-auto">
        <p class="text-center text-xl mb-6">
          {{ t('application_is_completed_in_30_seconds_') }}
        </p>
        <div class="flex justify-between items-center">
          <div class="w-5/12 text-center">
            <p class="text-lg mb-4">
              {{ t('for_those_who_want_to_know_more_about_features_and_costs') }}
            </p>
            <a-button
              class="w-52 !text-blue-600"
              shape="round"
              size="large"
              @click="downloadMaterials"
              >{{ t('product_document_download') }}
            </a-button>
          </div>
          <div class="w-1/12 flex justify-center">
            <div class="h-16 w-px bg-white"></div>
          </div>
          <div class="w-5/12 text-center">
            <p class="text-lg mb-4">
              {{ t('for_those_who_want_to_actually_see_the_system_screen') }}
            </p>
            <a-button
              type="primary"
              class="!bg-[#FFD866] !border-[#FFD866] !text-blue-600 w-52"
              shape="round"
              size="large"
              @click="openDemo"
            >
              {{ t('apply_for_free_demo') }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <div id="contact-us" class="mt-24 mb-2 pt-6 mx-[16px] md:mx-[72px]">
      <Contact ref="contactUs"></Contact>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  CloudDownloadOutlined,
  QuestionCircleOutlined,
  DoubleRightOutlined
} from '@ant-design/icons-vue';
import { ref, onMounted, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { downloadMaterials } from '@/utils';
import Contact from './Contact.vue';

let caseList = ref([
  {
    title: t('when_disposing_of_new_areas_and_items'),
    description: t('i_dont_know_who_to_ask_or_how_to_ask'),
    problem: t('im_new_to_the_area_and_dont_know_who_to_ask_or_how_to_dispose_of_items'),
    solution: t(
      'you_can_request_any_project_nationwide_collaborate_with_vendors_nationwide_through_online_reception'
    )
  },
  {
    title: t('i_requested_a_quote_via_email_but_the_response_was_slow'),
    description: t('current_status_is_unclear'),
    problem: t(
      'i_requested_a_quote_via_email_but_the_response_was_slow_and_the_current_status_was_unclear'
    ),
    solution: t(
      'our_professional_staff_will_respond_immediately_to_your_inquiries_you_can_also_check_various_statuses_and_conversation_history'
    )
  },
  {
    title: t('documents_for_manifests_and_administrative_reports'),
    description: t('management_is_complicated_and_troublesome'),
    problem: t(
      'managing_documents_for_manifests_and_administrative_reports_is_complicated_and_troublesome'
    ),
    solution: t(
      'centrally_manage_manifests_permits_and_emissions_quantities_track_transportation_and_disposal_status_in_real_time'
    )
  }
]);

const tasks = ref([
  {
    description: t('request_for_quotationinquiry'),
    icon: 'fa-fas fa-file',
    screens: [
      {
        description: t('estimate_screen'),
        details: [
          {
            description: t('for_any_project_in_any_area_please_contact_us_for_a_quote')
          },
          {
            description: t(
              'the_person_in_charge_will_contact_you_whether_or_not_we_can_accommodate_your_request_on_the_same_day'
            )
          }
        ]
      },
      {
        description: t('inquiry_employee_upload_image'),
        details: [
          {
            description: t(
              'share_information_about_the_waste_you_want_to_dispose_of_and_site_information_by_uploading_photos'
            )
          },
          {
            description: t(
              'we_can_smoothly_provide_detailed_estimates_without_the_need_for_onsite_adjustments'
            )
          }
        ]
      }
    ]
  },
  {
    description: t('estimate_confirmation_and_contract'),
    icon: 'fa-fas fa-file-pen',
    screens: [
      {
        description: t('estimate_confirmation_screen'),
        details: [
          {
            description: t(
              'once_the_estimate_has_been_finalized_you_will_be_notified_by_email_and_can_check_it_on_the_web_system'
            )
          },
          {
            description: t('if_there_is_no_problem_press_the_confirm_button_to_create_the_contract')
          }
        ]
      },
      {
        description: t('contract_creation_screen'),
        details: [
          {
            description: t(
              'simply_fill_in_the_necessary_information_in_the_contract_according_to_the_guidance'
            )
          },
          {
            description: t(
              'complete_online_by_linking_with_electronic_contract_service_cloud_service'
            )
          }
        ]
      }
    ]
  },
  {
    description: t('collection_request'),
    icon: 'fa-fas fa-truck',
    screens: [
      {
        description: t('collection_schedule_request_screen'),
        details: [
          {
            description: t(
              'requests_for_forwarding_or_lifting_of_containers_are_also_presented_with_schedule_requests_and_implemented_in_the_shortest_possible_schedule'
            )
          }
        ]
      },
      {
        description: t('upload_image_of_collection_location_information_etc'),
        details: [
          {
            description: t(
              'you_can_also_register_precautions_for_waste_disposal_locations_and_contact_information_saving_you_the_trouble_of_making_phone_calls_and_individual_contact'
            )
          }
        ]
      }
    ]
  },
  {
    description: t('work_status_management'),
    icon: 'fa-fas fa-magnifying-glass-chart',
    screens: [
      {
        description: t('manifest_management_screen'),
        details: [
          {
            description: t(
              'the_current_work_status_can_be_tracked_by_linking_with_jwnet_for_each_site_and_discharge_project'
            )
          }
        ]
      }
    ]
  },
  {
    description: t('data_management'),
    icon: 'fa-fas fa-truck',
    screens: [
      {
        description: t('manifest_details_screen'),
        details: [
          {
            description: t(
              'by_clicking_on_an_individual_project_you_can_immediately_view_the_necessary_information_for_the_manifest'
            )
          }
        ]
      },
      {
        description: t('achievement_screen'),
        details: [
          {
            description: t('you_can_check_the_collected_quantity_and_results_on_the_results_screen')
          }
        ]
      }
    ]
  },
  {
    description: t('claim'),
    icon: 'fa-fas fa-magnifying-glass-chart',
    screens: [
      {
        description: t('bill_management_screen'),
        details: [
          {
            description: t('you_can_check_invoices_and_details_according_to_actual_results')
          }
        ]
      },
      {
        description: t('payment_selection'),
        details: [
          {
            description: t('payment_can_be_made_by_invoice_or_credit_card')
          }
        ]
      }
    ]
  },
  {
    description: t('inquiry'),
    icon: 'fa-fas fa-truck',
    screens: [
      {
        description: t('inquiry_screen'),
        details: [
          {
            description: t(
              'if_you_have_any_problems_you_can_upload_photos_and_view_past_history_using_the_inquiry_tool'
            )
          }
        ]
      },
      {
        description: t('chat_image'),
        details: [
          {
            description: t(
              'for_urgent_inquiries_a_representative_will_respond_immediately_via_chat_format'
            )
          }
        ]
      }
    ]
  }
]);

const getImageUrl = (index: number, screenIndex: number) => {
  return new URL(`/src/assets/images/task-${index + 1}-${screenIndex + 1}.jpg`, import.meta.url)
    .href;
};

let onImageLoad = () => {};

onMounted(async () => {});

let contactUs = ref(null);
let openDemo = async () => {
  setTimeout(() => {
    let top = document.getElementById('contact-us');
    top.scrollIntoView({ block: 'start', behavior: 'smooth' });
    contactUs.value.formState.purpose = 'デモをお願いしたい';
  }, 0);
};
</script>

<style scoped>
.card-container {
  overflow: auto; /* Enable scrolling if content overflows */
}

.download-button-container {
  width: 100%; /* Ensure it spans the full width of the container */
  text-align: center;
}
</style>

<style lang="scss">
.ant-timeline .ant-timeline-item-tail {
  border-inline-start: 4px solid #9dc3e6 !important;
}

.ant-timeline .ant-timeline-item-head-custom {
  padding-block: 0px !important;
}

html {
  scroll-behavior: smooth;
}
</style>
